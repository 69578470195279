<template>
  <v-container
    id="training-plan-evolution"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-col
      cols="12"
    >
      <div class="row-flex-align-space">
        <v-btn
          color="default"
          class="mr-0"
          @click="back()"
        >
          {{ str['back'] }}
        </v-btn>
        <div>
          <div
            v-if="hasFilterDates"
            class="datepicker-element"
          >
            <v-menu
              v-model="filterDatepickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterDatesText"
                  :label="str['interval_dates']"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                />
              </template>
              <v-date-picker
                v-model="filterDates"
                range
                style="margin: 0;"
                @change="getExercisesProgress()"
                :locale="datepickerLanguage"
              />
            </v-menu>
          </div>
          <div
            v-if="showWeeksProgressChart && !hasFilterDates"
          >
            <v-btn-toggle
              :rounded="true"
              class="buttons-toggle-component"
            >
              <v-btn
                :color="viewMode === 'table' ? 'success' : 'transparent'"
                @click="viewMode = 'table'"
              >
                <v-icon>
                  mdi-table
                </v-icon>
              </v-btn>
              <v-btn
                :color="viewMode === 'chart' ? 'success' : 'transparent'"
                @click="viewMode = 'chart'"
              >
                <v-icon>
                  mdi-chart-bar
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>
      </div>
      <div
        v-if="showWeeksProgressChart && hasFilterDates"
        class="row-align-right pt-4"
      >
        <v-btn-toggle
          :rounded="true"
          class="buttons-toggle-component"
        >
          <v-btn
            :color="viewMode === 'table' ? 'success' : 'transparent'"
            @click="viewMode = 'table'"
          >
            <v-icon>
              mdi-table
            </v-icon>
          </v-btn>
          <v-btn
            :color="viewMode === 'chart' ? 'success' : 'transparent'"
            @click="viewMode = 'chart'"
          >
            <v-icon>
              mdi-chart-bar
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </div>
    </v-col>
    <v-col
      cols="12"
    >
      <v-data-table
        v-if="!showProgressTable && viewMode === 'table'"
        :headers="headers"
        :items="data"
        :search="search"
        :no-data-text="str['no_data']"
        :no-results-text="str['no_data']"
        class="elevation-1"
        @click:row="openItem"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="str['search']"
              single-line
              hide-details
            />
            <v-spacer />
          </v-toolbar>
        </template>
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
        </template>
      </v-data-table>
      <div v-if="showProgressTable && progressTable && viewMode === 'table'">
        <div
          v-if="!progressTable.data"
          class="text-center"
        >
          {{ str['no_data'] }}
        </div>
        <div
          v-if="progressTable.data"
          class="simple-table-scroll"
        >
          <div>
            <table
              v-for="(row, rowIndex) in progressTable.data"
              :key="rowIndex"
              class="simple-table theme"
              :class="{'mt-4': rowIndex > 0}"
            >
              <thead>
                <tr>
                  <th :colspan="showStepTable ? 3 : 5">
                    {{ row.plan ? row.plan.name : window.strings['training_plan'] }}
                  </th>
                  <th
                    v-for="(wk, wkIndex) in row.weeks"
                    :key="wkIndex"
                    colspan="2"
                    v-html="wk"
                    class="simple-table-min-header"
                  ></th>
                </tr>
                <tr>
                  <th
                    v-for="(header, headerIndex) in row.headers"
                    :key="headerIndex"
                  >
                    {{ header }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(val, valIndex) in row.values"
                  :key="valIndex"
                >
                  <td
                    v-for="(col, colIndex) in val"
                    :key="colIndex"
                    v-if="col.name !== null"
                    :rowspan="col.rowspan"
                    :style="{'background-color': progressTable.colors[val[0].colorIndex], 'color': '#fff', 'min-width': col.minWidth}"
                  >
                    <b v-if="colIndex === 0">
                      {{ col.name }}
                    </b>
                    <span
                      v-if="colIndex > 0"
                      class="text-nowrap"
                      style="font-size: 15px;"
                    >
                      {{ col.name }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="viewMode === 'chart'">
        <div
          v-for="(chart, chartIndex) in charts"
          :key="chartIndex"
          class="chart-container chart-container-colors-1"
        >
          <base-material-chart-card
            :data="chart"
            :options="chart.options"
            :type="chart.type"
            :color="chart.color"
            :size="chart.size"
            :sizelimit="chart.sizelimit"
            :shadow="true"
            hover-reveal
          >
            <div class="custom-chart-legend">
              {{ chart.title }}
            </div>
          </base-material-chart-card>
        </div>
      </div>
    </v-col>

    <v-dialog
      v-model="dialogItem"
      persistent
      max-width="750px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ itemOpened ? itemOpened.name : '' }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-col>
            <div class="progress-weeks">
              <div
                v-for="week, index in progressWeeks"
                :key="index"
                class="progress-weeks-week"
              >
                <div class="progress-weeks-week-name">
                  {{ str['week'] }} {{ index + 1 }} ({{ week.start }} - {{ week.end }})
                </div>
                <div class="progress-weeks-week-sets">
                  <div
                    v-for="set, setIndex in week.sets"
                    :key="setIndex"
                    class="progress-weeks-week-sets-set"
                  >
                    <div class="progress-weeks-week-sets-set-name">
                      {{ str['serie'] }} {{ set.id }}
                    </div>
                    <div class="progress-weeks-week-sets-set-items">
                      <div
                        v-for="day, dayIndex in set.days"
                        :key="dayIndex"
                        class="progress-weeks-week-sets-set-items-item"
                      >
                        <div class="progress-weeks-week-sets-set-items-item-day">
                          {{ moment(day.day).format('DD/MM') }}
                        </div>
                        <div
                          v-for="item, itemIndex in day.items"
                          :key="itemIndex"
                          class="progress-weeks-week-sets-set-items-item-name"
                        >
                          {{ item.load }}kg / {{ item.reps }}reps
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="dialogItem = false"
          >
            {{ str['close'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
  .progress-weeks-week {
    border: 1px solid #3498db;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .progress-weeks-week-name {
    text-align: center;
    background-color: #F8FBFC;
    font-weight: bold;
    padding: 5px;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #3498db;
  }

  .progress-weeks-week-sets {
    display: flex;
    width: 100%;
    overflow: auto;
  }

  .progress-weeks-week-sets-set {
    min-width: 200px;
    width: 100%;
    padding: 0 10px;
    border-right: 1px solid #3498db;
  }

  .progress-weeks-week-sets-set:last-child {
    border-right: none;
  }

  .progress-weeks-week-sets-set:first-child {
    border-radius: 0 0 4px 0;
  }

  .progress-weeks-week-sets-set:first-child:last-child {
    border-radius: 0 0 4px 4px;
  }

  .progress-weeks-week-sets-set-name {
    text-align: center;
    padding: 5px 0 0;
    font-weight: bold;
  }

  .progress-weeks-week-sets-set-items {
    width: fit-content;
    margin: 0 auto;
  }

  .progress-weeks-week-sets-set-items-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
  }

  .progress-weeks-week-sets-set-items-item-day {
    margin-right: 15px;
  }
</style>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'
  import moment from 'moment'

  export default {
    data () {
      const user = Utils.getUser()
      const client = Utils.getStorage('client')
      let showProgressTable = false
      if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_weeks_progress_table) {
        showProgressTable = true
      }
      let planWithWeeks = false
      if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.plan_with_weeks) {
        planWithWeeks = true
      }
      let showDayCategory = false
      if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_day_category) {
        showDayCategory = true
      }
      let hasSegmentedTraining = false
      if (user && user.configurations && user.configurations && user.configurations.training_plans && user.configurations.training_plans.has_segmented_training) {
        hasSegmentedTraining = true
      }
      let showWeeksProgressChart = false
      if (user && user.configurations && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_weeks_progress_chart) {
        showWeeksProgressChart = true
      }
      let hasTrains = false
      if (user && user.configurations && user.configurations && user.configurations.client && user.configurations.client.indexOf('trains') > -1) {
        hasTrains = true
      }
      let hasFilterDates = false
      if (user && user.configurations && user.configurations && user.configurations.training_plans && user.configurations.training_plans.has_progress_filter_dates) {
        hasFilterDates = true
      }
      let showStepTable = false
      if (user && user.configurations && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_step_table) {
        showStepTable = true
      }
      return {
        str: window.strings,
        datepickerLanguage: window.datepickerLanguage,
        user: user,
        moment: moment,
        client: client ? client : {},
        showProgressTable: showProgressTable,
        planWithWeeks: planWithWeeks,
        showDayCategory: showDayCategory,
        hasSegmentedTraining: hasSegmentedTraining,
        showWeeksProgressChart: showWeeksProgressChart,
        hasTrains: hasTrains,
        hasFilterDates: hasFilterDates,
        showStepTable: showStepTable,
        data: [],
        headers: [
          { text: window.strings['exercise'], value: 'name', align: 'left' },
        ],
        search: '',
        itemOpened: null,
        dialogItem: false,
        trainingPlan: null,
        progressWeeks: [],
        progressTable: null,
        daysCategoriesDict: this.getDaysCategoriesDict(showDayCategory),
        segmentedTrainingTypes: Utils.getSegmentedTrainingTypes(),
        filterDatepickerMenu: false,
        filterDates: [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        trngPlanIdBase: -999,
        viewMode: 'table',
        charts: [],
      }
    },
    computed: {
      filterDatesText () {
        if (this.filterDates[0] && this.filterDates[1]) {
          return this.filterDates.join(' / ')
        } else {
          return ''
        }
      },
    },
    watch: {
      dialogItem (val) {
        const dialog = document.getElementsByClassName('v-dialog')
        if (dialog && dialog[0]) {
          dialog[0].scrollTo(0, 0)
        }
        val || this.closeItem()
      },
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.getExercisesProgress()
    },
    methods: {
      getDaysCategoriesDict: function (enabled) {
        const dict = {}
        if (enabled) {
          const items = Utils.getTrngPlanDayCategories()
          if (items && items.length) {
            items.forEach(function (item) {
              dict[item.value] = window.strings[item.label] ? window.strings[item.label] : item.label
            })
          }
        }
        return dict
      },
      back: function () {
        this.$router.goBack()
      },
      getExercisesProgress: function () {
        const self = this
        this.filterDatepickerMenu = false
        this.$isLoading(true)
        Api.getTrainingPlanProgressExercises(this.client.dbId, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.data = response.data
            if (self.showProgressTable) {
              self.getWorkoutsProgress()
            }
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openItem(item) {
        this.loadChart = null
        this.repsChart = null
        this.itemOpened = item
        this.dialogItem = true
        this.getWorkoutProgress()
      },
      closeItem () {
        this.loadChart = null
        this.repsChart = null
        this.itemOpened = null
        this.dialogItem = false
      },
      getWorkoutProgress () {
        const self = this
        this.$isLoading(true)
        Api.getTrainingPlanProgressData({
          clientId: this.client.dbId,
          workoutId: this.itemOpened.id,
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.progressWeeks = self.getWorkoutProgressWeeks(response.data)
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getWorkoutsProgress () {
        const self = this
        this.$isLoading(true)
        Api.getTrainingPlanProgressData({
          clientId: this.client.dbId,
          workoutIds: this.data.map(a => a.id),
          startDate: this.hasFilterDates ? this.filterDates[0] : null,
          endDate: this.hasFilterDates ? this.filterDates[1] : null,
        }, function (response) {
          if (response.success) {
            self.drawTable(response.data, function () {
              self.drawChart(response.data)
            })
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      drawTable(progress, callback) {
        const self = this
        const plansDict = {}
        const plansIds = []
        const trains = []

        // compatibility
        const plansWithoutName = []
        progress.forEach(function (pr) {
          if ((!pr.tpd_name || !pr.tpds_name) && plansWithoutName.indexOf(pr.tp_id) === -1) {
            plansWithoutName.push(pr.tp_id)
          }
        })

        const plansMap = progress.reduce((acc, item) => {
          const planWithoutName = plansWithoutName.indexOf(item.tp_id) > -1 ? true : false
          if (item.tpd_name && !planWithoutName && !self.hasTrains) {
            item.tpd_name = item.tpd_name.trim()
            item.tpd_id = item.tpd_name
          }
          if (item.tpds_name && !planWithoutName && !self.hasTrains && !self.showStepTable) {
            item.tpds_name = item.tpds_name.trim()
            item.tpds_id = item.tpds_name
          }
          acc[item.tp_id] = acc[item.tp_id] || []
          acc[item.tp_id].push(item)
          if (!plansIds.includes(item.tp_id)) {
            plansIds.push(item.tp_id)
          }
          let trainIndex = trains.findIndex(function (tr) {
            return tr.tp_id === item.tp_id && tr.id === item.tpd_id
          })
          if (trainIndex === -1) {
            trains.push({
              tp_id: item.tp_id,
              id: item.tpd_id,
              name: item.tpd_name,
              steps: []
            })
            trainIndex = trains.length - 1
          }
          const stepIndex = trains[trainIndex].steps.findIndex(function (tr) {
            return tr.tp_id === item.tp_id && tr.tpd_id === item.tpd_id && tr.id === item.tpds_id
          })
          if (stepIndex === -1) {
            trains[trainIndex].steps.push({
              tp_id: item.tp_id,
              tpd_id: item.tpd_id,
              id: item.tpds_id,
              name: !self.showStepTable ? item.tpds_name : '',
              workout_id: item.workout_id,
            })
          }
          return acc
        }, {})
        const exercisesMap = self.data.reduce((acc, exercise) => {
          acc[exercise.id] = exercise.name
          return acc
        }, {})

        if (self.hasTrains) {
          Api.getTrains({
            client_id: self.client.dbId,
            start_date: self.hasFilterDates ? self.filterDates[0] : null,
            end_date: self.hasFilterDates ? self.filterDates[1] : null,
          }, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.trainingPlans = [{
                id: self.trngPlanIdBase,
                start_date: response.data[0] ? response.data.reduce((min, obj) => {
                  if (obj.date === '') return min
                  return obj.date < min ? obj.date : min
                }, response.data[0].date) : null,
                name: window.strings['training_plan'],
                days: response.data
              }]
              afterGetPlans()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          if (plansIds && plansIds.length) {
            Api.getTrainingPlans({
              client_id: self.client.dbId,
              sort_order: self.user && self.user.configurations && self.user.configurations.training_plans && self.user.configurations.training_plans.show_sort ? true : false,
              ids: plansIds,
            }, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.trainingPlans = response.data
                afterGetPlans()
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          } else {
            self.progressTable = 'no_data'
            self.$isLoading(false)
          }
        }

        function afterGetPlans() {
          self.trainingPlans.forEach((plan) => {
            plansDict[plan.id] = plan
            for (let t = 0; t < trains.length; t++) {
              if (trains[t].tp_id === plan.id) {
                const day = plan.days.find(function (d) {
                  return d.id === trains[t].id
                })
                if (day && !trains[t].name) {
                  trains[t].name = day.name ? day.name.trim() : ''
                }
                if (self.planWithWeeks && !self.hasTrains && day) {
                  const weekIdStr = plan.days.find(function (d) {
                    return d.week && d.week_id === day.week_id
                  })
                  if (weekIdStr) {
                    trains[t].week_id = weekIdStr.name
                  }
                }
                if (self.showDayCategory && !self.hasTrains && day) {
                  trains[t].category = self.daysCategoriesDict[day.category]
                }
                for (let s = 0; s < trains[t].steps.length; s++) {
                  if (!trains[t].steps[s].name) {
                    trains[t].steps[s].name = exercisesMap[trains[t].steps[s].workout_id] ? exercisesMap[trains[t].steps[s].workout_id].trim() : ''
                  }
                }
              }
            }
          })
          encodeData()
          callback()
        }

        function encodeData() {
          const tableData = []

          for (const planIdKey in plansMap) {
            const planId = parseInt(planIdKey)
            if (plansDict[planId]) {
              const planObj = {
                plan: plansDict[planId],
                weeks: [],
                headers: [],
                values: [],
                data: {}
              }
              const planProgress = self.getWorkoutProgressWeeks(plansMap[planId], planId).filter(function (w) {
                return w.sets && w.sets.length
              })
              const planTrains = trains.filter(function (tr) {
                return tr.tp_id === planId
              }).sort((a, b) => a.id - b.id)

              for (let t = 0; t < planTrains.length; t++) {
                const trainId = planTrains[t].id
                const trainName = (planTrains[t].week_id ? (planTrains[t].week_id + ' - ') : '') + (planTrains[t].category ? (planTrains[t].category + ': ') : '') + planTrains[t].name

                for (let w = 0; w < planTrains[t].steps.length; w++) {
                  const stepId = planTrains[t].steps[w].id
                  const stepName = planTrains[t].steps[w].name

                  planProgress.forEach((week, weekIndex) => {
                    const weekName = window.strings['week'] + ' ' + (weekIndex + 1) + '<br>' + '(' + week.start + ' | ' + week.end + ')'
                    if (!planObj.weeks.includes(weekName)) {
                      planObj.weeks.push(weekName)
                    }
                    week.sets.forEach((set) => {
                      const serie = set.id
                      const setData = set.data.filter(function (sd) {
                        return sd.tp_id === planId && sd.tpd_id === trainId && sd.tpds_id === stepId
                      })
                      const src = setData && setData.length ? setData[setData.length - 1] : null

                      let planStep = null
                      if (plansDict[planId].days && src) {
                        const planWithoutName = plansWithoutName.indexOf(planId) > -1 ? true : false
                        for (let d = 0; d < plansDict[planId].days.length; d++) {
                          const day = plansDict[planId].days[d]
                          if ((day.id === src.tpd_id || (!planWithoutName && day.name === src.tpd_name)) && day.steps) {
                            const stepFound = day.steps.find(function (st) {
                              if (planWithoutName) {
                                return st.id === src.tpds_id
                              } else {
                                const tpdsName = exercisesMap[st.workout_id] ? exercisesMap[st.workout_id].trim() : ''
                                return st.id === src.tpds_id || tpdsName === src.tpds_name
                              }
                            })
                            if (stepFound) {
                              planStep = stepFound
                              break
                            }
                          }
                        }
                      }

                      const planReps = getStepValue(planStep, serie, 'repeat')
                      const planLoad = getStepValue(planStep, serie, 'weight')

                      planObj.data[trainId] = planObj.data[trainId] ? planObj.data[trainId] : {}
                      planObj.data[trainId][stepId] = planObj.data[trainId][stepId] ? planObj.data[trainId][stepId] : {}
                      planObj.data[trainId][stepId][weekName] = planObj.data[trainId][stepId][weekName] ? planObj.data[trainId][stepId][weekName] : {}
                      planObj.data[trainId][stepId][weekName][serie] = planObj.data[trainId][stepId][weekName][serie] ? planObj.data[trainId][stepId][weekName][serie] : {}
                      planObj.data[trainId][stepId][weekName][serie] = {
                        reps: src ? src.reps : '-',
                        load: src ? src.load : '-',
                      }
                      const added = planObj.values.find(function (row) {
                        return row[0].id === trainId && row[1].id === stepId && row[2].id === serie
                      })
                      if (added) {
                        if (added[3] && added[3].name === '-' && added[4] && added[4].name === '-') {
                          added[3].name = planLoad
                          added[4].name = planReps
                        }
                      } else {
                        const valuesToPush = [{
                          id: trainId,
                          name: trainName,
                          minWidth: '120px',
                        }, {
                          id: stepId,
                          name: stepName,
                        }, {
                          id: serie,
                          name: self.showStepTable && src && src.tpds_name ? src.tpds_name : serie,
                        }]
                        if (!self.showStepTable) {
                          valuesToPush.push({
                            name: planLoad,
                          }, {
                            name: planReps,
                          })
                        }
                        planObj.values.push(valuesToPush)
                      }
                    })
                  })
                }
              }

              planObj.headers.push(window.strings['day'])
              planObj.headers.push(window.strings['exercise'])
              planObj.headers.push(window.strings['serie'])

              if (!self.showStepTable) {
                planObj.headers.push(window.strings['load_kg'])
                planObj.headers.push(window.strings['reps'])
              }

              planObj.weeks.forEach((wk) => {
                planObj.headers.push(window.strings['load_kg'])
                planObj.headers.push(window.strings['reps'])
                planObj.values.forEach(function (row) {
                  if (planObj.data[row[0].id] && planObj.data[row[0].id][row[1].id] &&
                    planObj.data[row[0].id][row[1].id][wk] && planObj.data[row[0].id][row[1].id][wk][row[2].id]
                  ) {
                    row.push({
                      name: planObj.data[row[0].id][row[1].id][wk][row[2].id].load,
                    })
                    row.push({
                      name: planObj.data[row[0].id][row[1].id][wk][row[2].id].reps,
                    })
                  } else {
                    row.push({
                      name: '-',
                    })
                    row.push({
                      name: '-',
                    })
                  }
                })
              })

              // clear empty rows
              for (let l = planObj.values.length - 1; l > 1; l--) {
                const row = planObj.values[l]
                let isEmpty = true
                for (let c = 3; c < row.length; c++) {
                  if (row[c].name !== '-') {
                    isEmpty = false
                    break
                  }
                }
                if (isEmpty) {
                  planObj.values.splice(l, 1)
                }
              }

              let lastTrain = null
              let lastStep = null
              let colTrain = null
              let colWorkout = null
              let colorIndex = 0
              planObj.values.forEach(function (v) {
                if (lastTrain === v[0].id) {
                  v[0].name = null
                  colTrain.rowspan += 1
                } else {
                  colorIndex = colorIndex === 0 ? 1 : 0
                  colTrain = v[0]
                  colTrain.rowspan = 1
                  lastTrain = v[0].id
                }
                if (lastStep === v[0].id + '-' + v[1].id) {
                  v[1].name = null
                  colWorkout.rowspan += 1
                } else {
                  colWorkout = v[1]
                  colWorkout.rowspan = 1
                  lastStep = v[0].id + '-' + v[1].id
                }
                v[0].colorIndex = colorIndex
              })

              if (planObj && planObj.values && planObj.values.length) {
                tableData.push(planObj)
              }
            }
          }

          self.progressTable = {
            colors: ['#3b3b3b', '#555'],
            exercises: exercisesMap,
            data: tableData.sort((a, b) => b.plan.id - a.plan.id),
          }

          function getStepValue(planStep, serie, fd) {
            if (self.hasSegmentedTraining && self.segmentedTrainingTypes && self.segmentedTrainingTypes.length && planStep) {
              let seriesCount = 0
              let verified = false
              for (let t = 0; t < self.segmentedTrainingTypes.length; t++) {
                const typeSeries = parseInt(planStep[self.segmentedTrainingTypes[t].key + 'repeat_id'])
                for (let c = 0; c < typeSeries; c++) {
                  seriesCount += 1
                  if (seriesCount === serie) {
                    verified = true
                    if (planStep[self.segmentedTrainingTypes[t].key + fd]) {
                      return planStep ? planStep[self.segmentedTrainingTypes[t].key + fd] : '-'
                    }
                    break
                  }
                }
                if (verified) {
                  break
                }
              }
            }
            return planStep ? planStep[fd] : '-'
          }
        }
      },
      getWorkoutProgressWeeks(data, planId) {
        const weeks = []
        const trainingPlan = this.trainingPlans && this.trainingPlans.length && planId ? this.trainingPlans.find((t) => t.id === planId) : null
        const earliestItem = !trainingPlan ? data.reduce((minItem, currentItem) => {
          return new Date(currentItem.date) < new Date(minItem.date) ? currentItem : minItem
        }) : null
        const today = moment().format('YYYY-MM-DD')
        const startDate = moment(trainingPlan ? trainingPlan.start_date : (earliestItem ? earliestItem.date : null))
        let day = startDate.subtract(1, 'days')
        let weekIndex = 0
        while (day.format('YYYY-MM-DD') <= today) {
          weeks[weekIndex] = {
            start: day.add(1, 'days').format('YYYY-MM-DD'),
            end: day.add(6, 'days').format('YYYY-MM-DD'),
            sets: [],
          }
          weekIndex += 1
        }

        data.forEach(function (item) {
          if (item.set_id) {
            const date = Utils.convertUtcDate(item.date, 'YYYY-MM-DD')
            const weekIndex = weeks.findIndex(function (wk) {
              return wk.start <= date && wk.end >= date
            })
            if (weekIndex > -1) {
              const index = weeks[weekIndex].sets.findIndex(function (set) {
                return set.id === item.set_id
              })
              if (index > -1) {
                weeks[weekIndex].sets[index].data.push(item)
              } else {
                weeks[weekIndex].sets.push({
                  id: item.set_id,
                  data: [item]
                })
              }
            }
          }
        })

        weeks.forEach(function (wk) {
          wk.sets.forEach(function (set) {
            const dates = {}
            set.data.forEach(function (item) {
              if (!dates[item.date]) {
                dates[item.date] = []
              }
              dates[item.date].push(item)
            })

            set.days = []
            for (const day in dates) {
              set.days.push({
                day: day,
                items: dates[day]
              })
            }
            set.days = set.days.sort(function (a, b) {
              if (a.date < b.date) return -1
              if (a.date > b.date) return 1
              return 0
            })
          })
        })
        return weeks
      },
      drawChart(progress) {
        if (this.trainingPlans && this.trainingPlans.length && this.showWeeksProgressChart) {
          const charts = []
          this.trainingPlans.forEach((plan) => {
            const labels = []
            const series = [[], []]
            let max = 0
            plan.days.forEach(function (day) {
              let sum = 0
              let progressSum = 0
              day.steps.forEach(function (step) {
                const series = step.repeat_id ? parseFloat(step.repeat_id) : null
                const load = step.weight ? parseFloat(step.weight) : null
                const reps = step.repeat ? parseFloat(step.repeat) : null
                const progressDone = progress.filter(item => item.tpd_id === day.id && item.tpds_id === step.id).reduce((maxItem, currentItem) => {
                  if (!maxItem) {
                    return currentItem
                  }
                  if (currentItem.reps > maxItem.reps || (currentItem.reps === maxItem.reps && currentItem.load > maxItem.load)) {
                    return currentItem
                  }
                  return maxItem
                }, null)
                if (series && load && reps) {
                  sum += series * load * reps
                }
                if (progressDone && progressDone.reps && progressDone.load) {
                  progressSum += series * progressDone.reps * progressDone.load
                }
              })
              labels.push(day.name)
              series[0].push({
                meta: day.name + ': ' + sum + '\n' + day.name + ' (' + window.strings['client'].toLowerCase() + '): ' + progressSum,
                value: sum
              })
              series[1].push({
                meta: day.name + ': ' + sum + '\n' + day.name + ' (' + window.strings['client'].toLowerCase() + '): ' + progressSum,
                value: progressSum
              })
              max = sum > max ? sum : max
              max = progressSum > max ? progressSum : max
            })
            charts.push({
              type: 'Bar',
              options: {
                lineSmooth: this.$chartist.Interpolation.cardinal({
                  tension: 0,
                }),
                low: 0,
                high: max + 10,
                chartPadding: {
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                },
                plugins: [
                  this.$chartist.plugins.tooltip({
                    appendToBody: true,
                  })
                ]
              },
              title: window.strings['volume_per_workout'],
              color: 'blue-grey darken-3',
              labels: labels,
              series: series,
              size: '400',
              sizelimit: '400'
            })
          })
          this.charts = charts
        }
      },
    },
  }
</script>
